import { apiGetAuth, apiGetAuthWithCustomHeadersResponseType } from '@/utils/api'
import { API_PRE_ORDER_STICKER, API_STORE } from '@/utils/api-url'
import { notificationDanger } from '@/utils/notification'
import { exportCustomHeaderAndResponseType } from '@/utils/helper'

const state = () => {
  return {
    list: { data: [] },
    store: { data: [] },
    data_modal_add_edit: {},
    meta_filter: {},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_LIST (state, payload) {
    state.list = payload
  },
  SET_STORE (state, payload) {
    state.store = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload){
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  }
}

const actions = {
  async getListStore (context, payload) {
    try {
      const response = await apiGetAuth(API_STORE.LIST_STORE, payload)
      context.commit('SET_STORE', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getList (context, payload) {
    try {
      const response = await apiGetAuth(API_PRE_ORDER_STICKER.LIST, payload)
      context.commit('SET_LIST', response.data.data)
      const meta = {...payload, store_id: payload?.store_id?.includes(",") ? undefined : payload?.store_id}
      context.commit('SET_META_FILTER', meta)
    } catch (error){
      notificationDanger(error)
    }
  },
  async getExport (_, payload) {
    try {
      const response = await apiGetAuthWithCustomHeadersResponseType(API_PRE_ORDER_STICKER.EXPORT, payload)
      exportCustomHeaderAndResponseType(response.data, `Export Pre Order Sticker.xlsx`)
    } catch (error) {
      notificationDanger(error)
      throw error
    }
  }
}

const getters = {
  list (state) {
    return state.list
  },
  list_store (state) {
    return state.store
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  meta_filter (state) {
    return state.meta_filter
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
