import { apiGetAuth, apiPostAuth, apiPutAuth } from '@/utils/api'
import { API_ORDER_MANAGEMENT, API_STORE } from '@/utils/api-url'
import { notificationDanger } from '@/utils/notification'

const state = () => {
  return {
    order: { data: [] },
    order_item: { data: [] },
    detail_order: { data: [] },
    store: { data: [] },
    order_type : { data: [] },
    preview: { data: [] },
    data_modal_add_edit: {},
    meta_filter: {},
    list_cancel_reason: {data:[]},
    toggle_modal_add_edit_discard: {
      targetModals: false,  
      title: '', 
      description: '',
      customTitleBtnOk: '',
      customClass: '',
      type: '',
      loadingTable: false,
      loadingPopUp: false
    }
  }
}

const mutations = {
  SET_ORDER (state, payload) {
    state.order = payload
  },
  SET_ORDER_ITEM (state, payload) {
    state.order_item = payload
  },
  SET_DETAIL_ORDER (state, payload) {
    state.detail_order = payload
  },
  SET_STORE (state, payload) {
    state.store = payload
  },
  SET_ORDER_TYPE (state, payload) {
    state.order_type = payload
  },
  SET_ORDER_PREVIEW (state, payload) {
    state.preview = payload
  },
  SET_DATA_MODAL_ADD_EDIT (state, payload){
    state.data_modal_add_edit = payload
  },
  SET_TOGGLE_MODAL_ADD_EDIT_DISCARD (state, payload) {
    state.toggle_modal_add_edit_discard = payload
  },
  SET_META_FILTER (state, payload) {
    state.meta_filter = payload
  },
  SET_LIST_CANCEL_REASON (state, payload) {
    state.list_cancel_reason = payload
  }
}

const actions = {
  async getListOrder (context, payload) {
    try {
      const response = await apiGetAuth(API_ORDER_MANAGEMENT.LIST_ORDER, payload)
      context.commit('SET_ORDER', response.data.data)
      const meta = {...payload, store_id: payload?.store_id?.includes(",") ? undefined : payload?.store_id}
      context.commit('SET_META_FILTER', meta)
    } catch (error){
      notificationDanger(error)
    }
  },
  async getDetailOrder (context, payload) {
    try {
      const response = await apiGetAuth(API_ORDER_MANAGEMENT.DETAIL_ORDER(payload))
      context.commit('SET_DETAIL_ORDER', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListOrderItem (context, payload) {
    try {
      const response = await apiGetAuth(API_ORDER_MANAGEMENT.LIST_ORDER_ITEM(payload.id), payload.reqBody)
      context.commit('SET_ORDER_ITEM', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListOrderType (context) {
    try {
      const response = await apiGetAuth(API_ORDER_MANAGEMENT.LIST_ORDER_TYPE)
      context.commit('SET_ORDER_TYPE', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListStore (context, payload) {
    try {
      const response = await apiGetAuth(API_STORE.LIST_STORE, payload)
      context.commit('SET_STORE', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getListCancelReason (context) {
    try {
      const response = await apiGetAuth(API_ORDER_MANAGEMENT.GET_LIST_CANCEL_REASON)
      context.commit('SET_LIST_CANCEL_REASON', response.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getOrderPreview (context, payload) {
    try {
      const response = await apiGetAuth(API_ORDER_MANAGEMENT.PREVIEW_ORDER(payload))
      context.commit('SET_ORDER_PREVIEW', response.data.data)
    } catch (error) {
      notificationDanger(error)
    }
  },
  async getNewDriver (context, payload) {
    try {
      const response = await apiPostAuth(API_ORDER_MANAGEMENT.GET_NEW_DRIVER(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async updateUnpickAll (context, payload) {
    try {
      const response = await apiPutAuth(API_ORDER_MANAGEMENT.UPDATE_UNPICK_ALL(payload))
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  },
  async putOrderItemsUpdate (context,payload){
    try {
      const response = await apiPutAuth(API_ORDER_MANAGEMENT.UPDATE_ORDER_ITEMS(payload.id),payload.data)
      return response.data
    } catch (error) {
      context.commit('SET_TOGGLE_MODAL_ADD_EDIT_DISCARD', {
        targetModals: false,  
        title: '', 
        description: '',
        customTitleBtnOk: '',
        customClass: '',
        type: '',
        loadingTable: false,
        loadingPopUp: false
      })
      notificationDanger(error)
    }
  }
}

const getters = {
  list_order (state) {
    return state.order
  },
  list_order_item (state) {
    return state.order_item
  },
  detail_order (state) {
    return state.detail_order
  },
  list_store (state) {
    return state.store
  },
  order_type (state) {
    return state.order_type
  },
  preview (state) {
    return state.preview
  },
  get_toggle_modal_add_edit_discard (state) {
    return state.toggle_modal_add_edit_discard
  },
  get_data_modal_add_edit (state) {
    return state.data_modal_add_edit
  },
  meta_filter (state) {
    return state.meta_filter
  },
  list_cancel_reason (state) {
    return state.list_cancel_reason
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
